import axios from 'axios';
import { mapKeys, mapValues, camelCase, snakeCase } from 'lodash';
import cookie from 'js-cookie';

// console.log('process.env.API_URL ,', process.env.API_URL);

// https://myblog-server.alaeddinemessadi.now.sh/api
// http://localhost:3001/api

// const instance = axios.create({
//   baseURL: 'https://some-domain.com/api/',
//   timeout: 1000,
//   headers: { 'X-Custom-Header': 'foobar' }
// });

function getAccessToken() {
	// @todo: load access token from cookie
	const token = cookie.get('token');
	return token;
}

function transformKeys(data, iteratee) {
	if (Array.isArray(data)) {
		return data.map(d => transformKeys(d, iteratee));
	}

	if (data instanceof Object) {
		return mapValues(
			mapKeys(data, (_, k) => iteratee(k)),
			v => transformKeys(v, iteratee)
		);
	}

	return data;
}

export function camelCaseKeys(data) {
	return transformKeys(data, camelCase);
}

export function snakeCaseKeys(data) {
	return transformKeys(data, snakeCase);
}

// @see: https://github.com/mzabriskie/axios#axios-api
export function request(method, url, config = {}, options = {}) {
	const { params, data, headers, maxContentLength } = config;

	// non-axios specific params
	const { suppressAuth } = options;

	const baseURL = process.env.API_URL || 'http://localhost:3001/api';

	// @see: https://tools.ietf.org/html/rfc6750
	const bearerToken = `Bearer ${getAccessToken()}`;

	return new Promise((resolve, reject) => {
		axios({
			method,
			baseURL,
			url,
			params,
			data: snakeCaseKeys(data),
			headers: suppressAuth ? headers : { ...headers, Authorization: bearerToken },
			maxContentLength
		})
			.then(response => {
				resolve({
					...response,
					data: camelCaseKeys(response.data)
				});
			})
			.catch(reject);
	});
}

export function get(url, config, options) {
	return request('GET', url, config, options);
}

export function post(url, config, options) {
	return request('POST', url, config, options);
}

export function put(url, config, options) {
	return request('PUT', url, config, options);
}

// not "delete()" because of reserved word
export function destroy(url, config, options) {
	return request('DELETE', url, config, options);
}
